.inputLoginContainer {
    display: flex;
    width: -webkit-fill-available;
    gap: 10px;
    flex-direction: column;
}

.inputLogin {
    background-color: #f9fafb;
    border: none;
    outline: none;
    border-radius: 5px;
}