.cardCuota {
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 5px;
    background-color: #FFFFFF;
    border-radius: 20px;
    margin: 0px 0px 0px 0px;
    justify-content: space-between;
    width: auto;
    min-width: 230px;

    @media (min-width: 421px) and (max-width: 1017px) {
        padding: 20px 50px 20px 50px;

    }

    @media (min-width: 0px) and (max-width: 420px) {
        width: auto;
        min-width: auto;
    }
}

.activo {
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 5px;
    background-color: #004994;
    border-radius: 20px;
    margin: 0px 0px 0px 0px;
    justify-content: space-between;
    width: auto;
    min-width: 230px;

    @media (min-width: 421px) and (max-width: 1017px) {
        width: auto;
    }

    @media (min-width: 0px) and (max-width: 420px) {
        width: auto;
        min-width: auto;
    }
}

.titleCard {
    color: #004993;
    font-size: 20px;
    font-family: Montserrat;
    line-height: 28px;
    font-weight: 700;
    border-bottom: 1px solid #D3D5DA;
}

.titleCardActiva {
    color: #FFFFFF;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 700;
    border-bottom: 1px solid #D3D5DA;
}

.subtitleCard {
    border-bottom: 1px solid #D3D5DA;
    gap: 5px;
    display: flex;
    flex-direction: column;
    padding: 0px 5px 5px 0px;
}

.anticipoCard {
    font-size: 14px;
    line-height: 19.60px;
    font-family: Montserrat;
    font-weight: 400;
}

.anticipoCardActiva {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 19.60px;
    font-family: Montserrat;
    font-weight: 400;
}

.contadoCard {
    color: #4D4D4D;
    font-size: 10px;
    font-family: Montserrat;
    font-size: italic;
    line-height: 12px;
    font-weight: 700;
}

.contadoCardActiva {
    color: #FFFFFF;
    font-size: 10px;
    font-family: Montserrat;
    font-size: italic;
    line-height: 12px;
    font-weight: 700;
}

.descuentoCard {
    color: #4D4D4D;
    font-size: 16px;
    font-family: Montserrat;
    font-size: italic;
    line-height: 12px;
    font-weight: 700;
}

.descuentoCardActiva {
    color: #FFFFFF;
    font-size: 16px;
    font-family: Montserrat;
    font-size: italic;
    line-height: 12px;
    font-weight: 700;
}

.anticipo {
    color: #4D4D4D;
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 500;
}

.pago {
    display: flex;
    flex-direction: row;
    gap: 5px;

    @media (min-width: 0px) and (max-width: 355px) {
        flex-direction: column;
    }
}

.cuotas {
    display: flex;
    align-items: center;
    color: #4D4D4D;
    font-size: 12px;
    line-height: 16.20px;
    font-family: Montserrat;
    font-weight: 400;
}

.cuotasActiva {
    display: flex;
    align-items: center;
    color: #FFFFFF;
    font-size: 12px;
    line-height: 16.20px;
    font-family: Montserrat;
    font-weight: 400;
}

.valorCuotas {
    color: #004993;
    font-size: 20px;
    font-family: Montserrat;
    line-height: 28px;
    font-weight: 700;
}

.valorCuotasActiva {
    color: #FFFFFF;
    font-size: 20px;
    font-family: Montserrat;
    line-height: 28px;
    font-weight: 700;
}

.flechaCard {
    display: flex;
    justify-content: center;
    transition: 1s;
    background-color: #FFFFFF;
}

.flechaCardActiva {
    display: none;
    align-self: center;
    width: auto;
    height: auto;
}

.textSeleccionar {
    display: none;
    width: 0%;
    color: #FFFFFF;
    font-weight: 500;
}

.textSeleccionado {
    display: flex;
    z-index: 1;
    width: 90%;
    color: #FFFFFF;
    font-weight: 500;
}

.ButtonSeleccionar {
    border: 1px solid #004993;
    border-radius: 30px;
    width: 30px;
    transition: 0.5s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 30px;
    max-height: 30px !important;
}

.ButtonSeleccionado {
    border: 1px solid #004993;
    border-radius: 30px;
    width: 78%;
    transition: 0.5s;
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-direction: row;
}

.cardCuota:hover .ButtonSeleccionar {
    padding: 0px 10px 0px 15px;
    width: 65%;
    background-color: #004993;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: end;
}

.cardCuota:hover .textSeleccionar {
    display: flex;
    width: auto;
    /* border:solid 1px white */
}

.cardCuota:hover .flechaCard {

    background-color: #004993;
    display: none;
}

.cardCuota:hover {
    cursor: pointer;
}

@media (max-width: 500px) {
    .cardCuota {
        width: 65%;
        min-width: auto;
        padding: 20px 50px 20px 50px;
        margin: 20px 0px 0px 0px;
    }

    .activo {
        width: 65%;
        min-width: auto;
        padding: 20px 50px 20px 50px;
        margin: 20px 0px 0px 0px;
    }
}