.ProfileContainer {
  display: flex;
  width: -webkit-fill-available;
  justify-content: space-evenly;
  flex-direction: column;
}

.ContainerSection {
  width: 70%;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 50px;

  padding: 75px 0px;
  border: none;
  border-radius: 20px;
  box-shadow: rgb(135 196 232) 0px 0px 29px -6px;
}

.ContainerDatos {
  display: flex;
  justify-content: space-between;
  width: 80%;
  align-self: center;
}

.RowDato {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Dato {
  border: none;
  border-radius: 20px;
  padding: 5px 25px;
  text-align: center;
  background-color: #f2f2f2;
}

.ButtonCerrarSesion {
  color: #f9fafb;
  background-color: #004993;
  border-radius: 30px;
  border: none;
  height: 45px;
  width: 100%;
  max-width: 160px;
  font-size: 16px;
  font-weight: 400;
}

.slide {
  border: none;
  border-radius: 20px;
  padding: 5px 25px;
  text-align: center;
  background-color: #f2f2f2;
  outline: none;
}
