.Footer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-image: url('./texturaFooter.png');
    background-size: 116%;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 35px;
    margin-bottom: 15px;
}

.rowTop {
    display: flex;
    width: 55%;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

.rowMid {
    font-size: 14px;
}

.rowBot {
    font-size: 14px;
}