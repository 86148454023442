.LoginInputsContainer {
  display: flex;
  flex-direction: column;
  padding: 50px 75px;
  border: none;
  border-radius: 20px;
  gap: 20px;
  width: 475px;
  background-color: #bbe3eb;
}
