.containerGral {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subContainerGral {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  gap: 30px;
  margin-top: 40px;
}

.extraContenido {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 100px;
  height: -webkit-fill-available;
}

.contenido {
  width: 210mm;
  height: -webkit-fill-available;
  font-family: Arial;
  background-color: white;
}

.item {
  height: auto;
}

.imagen {
  width: 200px;
  height: auto;
}

.recibo {
  width: 595px;
  height: 842px;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
}

.containerLogo {
  width: 192.95px;
  height: 51.99px;
  flex-shrink: 0;
}

.containerText {
  width: 215px;
  height: 40px;
  flex-shrink: 0;
}

.sideTitle {
  display: flex;
  width: 238px;
  height: 52px;
  flex-direction: column;
  flex-shrink: 0;
}

.text {
  color: var(--azul, #004994);
  text-align: right;
  font-size: 18px;
  font-size: normal;
  font-weight: 400;
  line-height: normal;
}

.containerDatos {
  height: 100%;
  width: fit-content;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0px 20px 0px 20px;
  gap: 15px;
}

.headerDatos {
  display: flex;
  flex-direction: column;
  justify-content: inherit;
  align-items: baseline;
}

.titleDatos {
  display: flex;
  width: 356px;
  height: auto;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
}

.recibo {
  color: var(--azul, #004994);
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  display: flex;
  flex-direction: column;
  height: min-content;
  width: -webkit-fill-available;
  gap: 25px;
  margin: 0px;
}

.boxInputs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.containerInput {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.containerInputCotizacion {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: fit-content;
  align-self: center;
}

.containerInputReserva {
  display: flex;
  flex-direction: column;
}

.titleInput {
  font-size: large;
  text-align: center;
}

.diaMesAño {
  width: 20%;
  border: 2px;
  border-radius: 20px;
}

.nombreApellido {
  width: auto;
  display: flex;
  flex-direction: column;
}

.bodyForm {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 200;
  padding: 0px 10px 0px 10px;
}

.parrafoReserva {
  margin-top: 15px !important;
  color: #495057;
}

.input {
  background-color: #fff;
  border-radius: 25px;
  height: 45px;
  font-size: 15px;
  border-width: thin;
  display: flex;
  margin: 10px;
  text-align: center;
}

.inputReserva {
  background-color: #fff;
  border-radius: 25px;
  height: 45px;
  font-size: 15px;
  border-width: thin;
  display: flex;
  margin: 10px;
  text-align: center;
  width: max-content;
  align-self: center;
}

.inputFecha {
  background-color: #e9ecef;
  border: none;
  font-size: 15px;
  text-align: center;
  width: 5%;
  color: rgb(73, 80, 87);
}

.buttonPDF {
  width: 200px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid #03579a;
  background: rgba(0, 74, 147, 0.3);
  color: black;
  margin: 50px 25px;
}

.containerFooter {
  width: 56%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.textSup {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.textMid {
  text-align: center;
}

.textInf {
  text-align: center;
}

.containerFirma {
  display: flex;
  justify-content: space-around;
}

.firma {
  padding: 0px 40px 15px 0px;
}

.containerTextura {
  background-image: url("./texturaHeader.png");
  background-size: cover;
  background-position: center;
  height: 103.3px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer {
  height: 12%;
  background-image: url("./texturaFooter.png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
}

.espacioFirma {
  font-size: 16px;
  color: #495057;
  display: flex;
  align-self: self-end;
}
