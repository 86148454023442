.contenido {
    width: 210mm;
    height: -webkit-fill-available;
    padding: 20px;
    font-family: Arial;
    background-color: white;
}

.recibo {
    width: 595px;
    height: 842px;
    background-color: #FFF;
    display: flex;
    justify-content: space-around;
    color: var(--azul, #004994);
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.titleDatos {
    display: flex;
    width: 372px;
    height: 59px;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    margin-bottom: 9px;
    margin-top: 50px;
}

.containerFecha {
    flex-shrink: 0;
    border: 2px;
    border-radius: 20px;
    margin: 20px;
    width: 30%;
    height: 45%;
    display: flex;
    flex-direction: row;
}

.buttons {
    display: flex;
    column-gap: 70px;
}

.input {
    background-color: #fff;
    border-radius: 25px;
    border-left: none;
    height: 45px;
    font-size: 15px;
    border-width: thin;
    display: flex;
    margin: 10px;
    text-align: center;
}

.buttonPDF {
    width: 200px;
    height: 50px;
    border-radius: 50px;
    border: 1px solid #03579A;
    background: rgba(0, 74, 147, 0.30);
    color: black;
    margin: 25px;
    align-self: center;
}

.boxInputs {
    width: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.containerInput {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
}

.containerFechaFirma {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    align-items: center;
    border-top: solid 2px;
    padding: 50px;
}

.subContainer {
    display: flex;
    flex-direction: row;
    column-gap: 50px;
}

.subContainerInput {
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding: 20px;
    align-items: center;
}

.titleInput {
    font-size: large;
    text-align: center;
}

.seccionInputs {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    border-top: solid 2px;
}

.titleSeccion {
    color: var(--azul, #004994);
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.containerFirma {
    display: flex;
    justify-content: flex-end;
}

.firma {
    padding: 0px 40px 15px 0px;
}