.ContGeneral {
    display: flex;
    justify-content: center;
    width: auto;
    justify-content: space-evenly;
    gap: 20px;
}

.ContainerRecibo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    padding: 50px 0px 50px 0px;
    width: 800px;
}

.Header {
    display: flex;
    justify-content: space-around;
    background-image: url('./texturaHeader.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 103.3px;
    flex-shrink: 0;
    align-items: center;
    position: relative;
    bottom: 35px;
}

.logoHeader {
    align-self: center;
    scale: 160%;
    position: relative;
    left: 30px;
}

.textHeader {
    color: #004994;
    align-self: center;
    width: 35%;
    text-align: center;
    position: relative;
    left: 45px;
    font-size: 20px;
}

.BodyRecibo {
    padding: 20px 30px;
}

.topBody {
    display: flex;
    justify-content: space-between;
}

.Title {
    color: #004994;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 1px;
}

.ContainerFecha {
    display: flex;
    align-items: center;
    width: 30%;
}

.Fecha {
    color: #6c757d;
    border: 1px solid #6c757d;
    border-radius: 20px 0px 0px 20px;
    padding: 5px;
    background: #f8f9fa;
}

.rowDataFecha {
    display: flex;
    border: 1px solid #6c757d;
    padding: 5px;
    border-radius: 0px 20px 20px 0px;
    background: #f8f9fa;
    color: #6c757d;
    width: -webkit-fill-available;
    justify-content: space-around;
}

.datosBody {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: 3px solid #004994;
}

.ContainerNombreApellido {
    display: flex;
    border: 1px solid #6c757d;
    border-radius: 20px;
    padding: 5px 10px;
    background: #f8f9fa;
    color: #6c757d;
    display: flex;
    gap: 20px;
}

.RowContainers {
    width: -webkit-fill-available;
    display: flex;
    gap: 20px;
}

.Container {
    width: -webkit-fill-available;
    border: 1px solid #6c757d;
    border-radius: 20px;
    padding: 5px 10px;
    background: #f8f9fa;
    color: #6c757d;
    display: flex;
    gap: 20px;
}

.ContainerObservaciones {
    width: -webkit-fill-available;
    border: 1px solid #6c757d;
    border-radius: 20px;
    padding: 5px 10px;
    background: #f8f9fa;
    color: #6c757d;
    display: flex;
    gap: 20px;   
}

.titleObservaciones {
    color: #004994;
    font-size: 21px;
    font-weight: 700;
    padding-top: 20px;
    padding-bottom: 10px;
    letter-spacing: 1px;
}

.ContainerObservaciones {
    width: auto;
    height: 150px;
    background: #e9ecef;
    border-radius: 10px;
    padding: 20px 20px 20px 20px;
}

.subBody {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
}

.ContainerRecibi {
    color: #004994;
    display: flex;
    align-items: center;
    gap: 20px;
}

.Recibi {
    font-size: 21px;
    font-weight: 700;
}

.ContainerTotal {
    color: #004994;
    display: flex;
    background: #e9ecef;
    border-radius: 20px;
    padding: 10px;
    width: 200px;
    font-weight: 700;
    gap: 20px;
}

.Total {
    display: flex;
}

.Footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    background-image: url('./texturaFooter.png');
    background-size: 116%;
    background-position: center;
    background-repeat: no-repeat;
    height: 103.3px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    top: 35px;
}

.rowTop {
    display: flex;
    width: 55%;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

.rowMid {
    font-size: 14px;
}

.rowBot {
    font-size: 14px;
}

.buttonPDF {
    align-self: center;
    width: 150px;
    height: 50px;
    border-radius: 26px;
    border: 1px solid #03579A;
    background: rgba(0, 74, 147, 0.30);
    color: black;
}