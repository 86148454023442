.containerFecha {
  flex-shrink: 0;
  border: 2px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: auto;
}

.titleFecha {
  border-radius: 25px 0px 0px 25px;
  color: rgb(118, 118, 118);
  background-color: #f8f9fa;
  border-width: thin;
  text-align: center;
  font-size: large;
  line-height: 2;
  border-style: solid;
  border-right: none;
  font-weight: 500;
  margin: 0px;
  display: flex;
  align-self: center;
  padding: 0px 10px 0px 10px;
}

.inputFecha {
  border-width: thin;
  background-color: #f8f9fa;
  height: 35px;
  font-size: large;
  text-align: center;
  display: flex;
  justify-content: center;
  border-style: solid;
  border-color: #6c757d;
  border-right: none;
  align-items: center;
}

.containerBarra {
  border-width: thin;
  background-color: #f8f9fa;
  height: 35px;
  font-size: x-large;
  text-align: center;
  display: flex;
  justify-content: center;
  border-style: solid;
  border-color: #6c757d;
  border-left: none;
  border-right: none;
  align-items: center;
}

.barra {
  width: 100%;
  border-left: none;
  border-right: none;
  background-color: #f8f9fa;
  border-width: 0px;
  font-size: large;
  text-align: center;
  position: relative;
  top: 9px;
  height: 11px;
  margin-bottom: 0px;
}

.subContainerNums {
  display: flex;
  align-items: center;
  align-self: center;
  font-size: 18px;
  color: #004994;
  background-color: #f8f9fa;
  border: rgb(118, 118, 118) 1px solid;
  border-radius: 0px 25px 25px 0px;
  height: 38px;
  padding: 0px 10px 0px 10px;
}
