.ContainerGeneral {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: -webkit-fill-available;
}

.ContainerInputs {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 36px;
}

.InputsFecha {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.TitleInput {
    align-self: center;
    font-size: 24px;
    font-weight: 700;
}

.inputs {
    display: flex;
    gap: 15px;
}

.ContainerInput {
    display: flex;
    flex-direction: column;
}

.input {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    background-color: #fff;
    border-radius: 25px;
    border-left: none;
    height: 45px;
    font-size: 15px;
    border-width: thin;
    display: flex;
    margin: 10px;
    text-align: center;
}

.label {
    align-self: center;
    font-size: 20px;
}

.InputsDatos {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.rowInputs {
    display: flex;
    gap: 15px;
    justify-content: center;
}

.ContainerButtons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
}

.rowButtons {
    display: flex;
    width: min-content;
    align-self: center;
    flex-direction: row;
}

.button {
    padding: 5px;
    width: max-content;
    margin: 20px;
    color: white;
    border: 0.5px solid #006ee0;
    border-radius: 10px;
}