.buttonIngresar {
  color: #f9fafb;
  background-color: #004993;
  border-radius: 30px;
  border: none;
  height: 45px;
  width: 100%;
  max-width: 160px;
  font-size: 16px;
  font-weight: 400;
}
