.ContainerCotizacion {
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: 70%;
  align-self: center;
}

.ContainerAlquileres {
  display: flex;
  flex-direction: row;
  height: fit-content;
}

.ContainerButtonsTitle {
  display: flex;
  flex-direction: column;
}

.TopHeader {
  display: flex;
  flex-direction: column;
}

.arrayInput {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}

.labelInput {
  text-align: center;
}

.TitleHeader {
  font-size: 24px;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.ContainerButtonsHeader {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}

.ContainerButtons {
  display: flex;
  width: min-content;
  align-self: center;
  flex-direction: row;
}

.Button {
  padding: 5px;
  width: max-content;
  margin: 20px;
  color: white;
  border-radius: 10px;
  border: 0.5px solid #006ee0;
}

.TitleInputs {
  font-size: 20px;
  text-align: center;
  padding: 30px 0px 20px 0px;
}

.OtrosInputs {
  display: flex;
  padding: 20px 20px 40px 20px;
  flex-direction: column;
  border-bottom: 1px solid gray;
}

.Label {
  width: min-content;
  align-self: center;
}

.ContainerInput {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.containerInputCotizacion {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: fit-content;
  align-self: center;
}

.Input {
  width: min-content;
  align-self: center;
  flex-direction: column;
  display: flex;
}

.InputRow {
  width: min-content;
  align-self: center;
  flex-direction: row;
  display: flex;
}

.ContInput {
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  justify-content: space-between;
}

.ContInputReserva {
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ContInputRecibo {
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
}

.ContainerInputsCliente {
  font-size: 20px;
  text-align: center;
  padding-top: 10px;
}

.InputsCliente {
  display: flex;
  /* padding: 20px 20px 40px 20px; */
  flex-direction: column;
  border-bottom: 1px solid gray;
  width: -webkit-fill-available;
  /* height: 260px; */
  justify-content: center;
  height: -webkit-fill-available;
  justify-content: space-between;
}

.InputsClienteReserva {
  display: flex;
  padding: 20px;
  flex-direction: column;
  border-bottom: 1px solid gray;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  justify-content: center;
}

.InputsClienteRecibo {
  display: flex;
  padding: 10px;
  flex-direction: column;
  border-bottom: 1px solid gray;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  justify-content: center;
  height: auto;
}

.Inputs {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  justify-content: center;
  height: -webkit-fill-available;
}

.InputsReserva {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  justify-content: center;
}

.ContainerButtonPDF {
  display: flex;
  justify-content: center;
}

.ButtonPDF {
  width: 200px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid #03579a;
  background: rgba(0, 74, 147, 0.3);
  color: black;
  margin: 30px;
}

.ContainerPDF {
  display: flex;
  background-color: white;
  flex-direction: column;
  /* justify-content: space-evenly; */
  gap: 50px;
}

.ContainerHeaderPDF {
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 25px;
  align-self: center;
  padding: 25px 0px 25px 0px;
}

.HeaderPDF {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.LogoHeaderPDF {
  height: 37px;
  width: 191px;
  margin-left: 58px;
  position: relative;
  top: 25px;
  scale: 160%;
}

.TitlePDF {
  font-weight: bolder;
  font-size: 30px;
  align-self: flex-end;
  position: relative;
  top: 20px;
}

.RecuadroTop {
  display: flex;
  background-color: white;
  flex-direction: column;
  gap: 25px;
  width: 90%;
  align-self: center;
}

.SubHeaderPDF {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 180px;
  position: relative;
  top: 20px;
}

.subTitle {
  color: #004994;
  font-weight: 500;
  align-self: self-end;
}

.SubHeaderSide {
  display: flex;
  gap: 150px;
}

.Asesor {
  font-size: 20px;
}

.BodyPDF {
  display: flex;
  flex-direction: column;
}

.RecuadroCliente {
  display: flex;
  flex-direction: column;
  border: solid 1px #e6f4ff;
}

.TitleRecuadro {
  width: -webkit-fill-available;
  font-size: 20px;
  background-color: #e6f4ff;
  border: solid 1px #e6f4ff;
  padding: 5px 5px 5px 10px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #004994;
}

.RecuadroPropiedad {
  display: flex;
  flex-direction: column;
  border: solid 0.5px #e6f4ff;
  border-radius: 0px 0px 15px 15px;
}

.Title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.SideTitleContainer {
  display: flex;
  flex-direction: row;
  gap: 25px;
  color: #000;
  font-weight: 100;
}

.ContainerCheckbox {
  display: flex;
  align-items: center;
  gap: 5px;
}

.labelCheckbox {
  font-size: 18px;
}

.ContenidoClientes {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.RowsContenido {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 75%;
}

.RowDatosCliente {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 100px;
}

.DatoCliente {
  width: -webkit-fill-available;
  font-size: 18px;
}

.ContenidoPropiedad {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
}

.ContainerM2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.subContainerValores {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: -webkit-fill-available;
  border-top: 1px solid #cce1f3;
  padding: 10px 0px 0px 0px;
}

.RecuadroAlquiler {
  display: flex;
  flex-direction: column;
  border: solid 1px #cbcbcb;
  border-radius: 15px 15px 15px 10px;
}

.TitleRecuadroAlquiler {
  width: -webkit-fill-available;
  background-color: #004994;
  border: solid 1px #006ee0;
  padding: 10px 15px 10px 15px;
  color: white;
  border-radius: 15px 15px 0px 0px;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
}

.DatosAlquiler {
  display: flex;
  padding: 15px 0px 15px 10px;
  flex-direction: column;
  justify-content: space-around;
}

.BloqueAbono {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.DescuentoAplicado {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.text {
  font-size: 12px;
}

.CostoGarantia {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 10px;
  border-left: 1px solid #cce1f3;
  width: 500px;
}

.TextCosto {
  font-size: 24px;
  text-align: center;
}

.ContainerCosto {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ContainerCostoAnterior {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.Antes {
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
}

.Garantia {
  font-size: 24px;
  text-align: center;
  font-weight: bolder;
  color: #80a8cf;
}

.ContainerCostoActual {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TextCostoActual {
  font-size: 14px;
  display: flex;
  justify-content: center;
}

.ValorGarantia {
  font-size: 40px;
  text-align: center;
  font-weight: bolder;
  color: #004994;
}

.ValorSinIva {
  display: flex;
  align-self: flex-end;
  font-size: 12px;
  align-self: center;
}

.ContainerSeguro {
  width: -webkit-fill-available;
  background-color: #80a8cf;
  padding: 10px 15px 10px 15px;
  color: white;
  border-radius: 15px 15px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.textSeguro {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
}

.DatosIncendio {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 15px 15px 15px 10px;
}

.ContainerDatosIncendio {
  display: flex;
  flex-direction: row;
  gap: 200px;
  padding-bottom: 15px;
}

.textIncendio {
  display: flex;
  flex-direction: column;
}

.costoSeguroIncendio {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 15px;
  border-left: 1px solid #cce1f3;
  width: 275px;
}

.valorCuotaIncendio {
  font-size: 40px;
  text-align: center;
  font-weight: bolder;
  color: #004994;
}

.ContainerContratacion {
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 2px;
  padding: 0px 0px 25px 0px;
}

.logoWpp {
  padding: 0px 20px 0px 20px;
  height: 32px;
  width: 32px;
}

.ContainerSeguroAuto {
  display: flex;
  justify-content: flex-end;
  width: 95%;
}

.BarraAzul {
  display: flex;
  justify-content: space-between;
  background-color: #e5f1ff;
  height: 65px;
  align-items: center;
  width: 91%;
  align-self: center;
  border-radius: 10px;
}

.Auto {
  scale: 200%;
  position: relative;
  bottom: 15px;
  right: 37px;
}

.ContainerTextSeguro {
  padding-right: 20px;
  font-weight: 500;
  flex-direction: row;
  display: flex;
  width: 100%;
  font-size: 24px;
  gap: 12px;
}

.RowTextSeguro {
  display: flex;
  gap: 10px;
}

.TextSeguroAuto {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.TextReintegro {
  font-size: 30px;
  font-weight: 600;
  padding-right: 25px;
}

.Cotizalo {
  border-left: 1px solid #cbcbcb;
  font-size: 30px;
  font-weight: 600;
  color: #004994;
  padding-left: 35px;
}

.ContainerFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 98%;
}

.SubContainerFooter {
  display: flex;
  flex-direction: column;
  width: 75%;
  padding-left: 40px;
}

.RowTopFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 0px 25px 0px;
  width: 102%;
}

.LeftFooter {
  display: flex;
  gap: 55px;
}

.logoFooter {
  align-self: center;
  scale: 200%;
  display: flex;
  position: relative;
  left: 12px;
  top: 5px;
}

.DatosFooter {
  display: flex;
  flex-direction: column;
  border-right: solid 1px gray;
  font-size: 20px;
  padding-right: 22px;
}

.logoDelSur {
  scale: 145%;
  position: relative;
  top: 20px;
  display: flex;
  align-self: self-start;
}

.textDisclaimer {
  font-size: 12px;
  display: flex;
  align-self: center;
  color: #004994;
  padding-bottom: 25px;
  width: 100%;
  /* padding-left: 32px; */
}

.ContainerQR {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.InputsComisiones {
  display: flex;
  width: min-content;
  align-self: center;
  flex-direction: row;
  gap: 20px;
}

.InputDescuento {
  display: flex;
  width: auto;
  align-self: center;
  flex-direction: column;
}

.ButtonCalcular {
  padding: 5px;
  width: max-content;
  margin: 20px;
  background-color: #004994;
  color: white;
  border: 0.5px solid #006ee0;
  border-radius: 10px;
}
