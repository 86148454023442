.ButtonsFilter {
  margin-right: 10px;
  color: white !important;
  padding: 8px 20px 8px 20px;
}

.ButtonRight {
  background: #08589d;
  color: white !important;
  padding: 8px 20px;
}

.ButtonFormSelect {
  height: 45px;
  width: 90%;
  border-radius: 54px;
  background: #e7e7e7;
  padding: 0px;
}

.FormSelect {
  height: 45px;
  width: -webkit-fill-available;
  border-radius: 54px;
  background: #e7e7e7;
}

.SubmitContainer {
  display: flex;
  flex-direction: row;
  gap: 60px;
}

.containerButtonLogo {
  background: rgb(12, 91, 158);
  color: white;
  padding: 10px 14px;
  font-weight: 900;
  border-radius: 35px;
  width: -webkit-fill-available;
  text-align: center;
}

.labelLogo {
  position: absolute;
  cursor: pointer;
  width: auto;
}

.Button {
  background: #0c5b9e;
  color: white;
  padding: 10px 45px 10px;
  font-weight: 900;
}

.subEditBox {
  background: rgb(12, 91, 158);
  color: white;
  padding: 10px 45px;
  font-weight: 900;
  border-radius: 35px;
  width: -webkit-fill-available;
  text-align: center;
}

.textEditDatos {
  cursor: pointer;
  color: white;
  padding: 10px 45px;
  font-weight: 900;
  border-radius: 35px;
}
