.titleCRM {
  display: flex;
  flex-direction: row;
  width: 475px;
  justify-content: center;
  gap: 15px;
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
}

.LoginContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  height: 100vh;
  width: -webkit-fill-available;
  align-items: center;
  background-color: #009fbb;
}
