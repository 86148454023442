.dropdown {
    position: relative;
    display: inline-block;
    border-radius: 10px;
    padding-left: 18px;
    width: 222px;
    border: none;
    background-color: #F9FAFB;
    outline: none;
    color: #1A1A1A;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    box-sizing: border-box; 
    @media (min-width: 0px) and (max-width: 1017px) {
        width: 100%;
 
        border-radius: 8px;
        padding: 0px 36px 0px 36px;
    } 
  }
  


  
  .selectedOption {

    /* border: 1px solid #ccc; */
    cursor: pointer;
   
    height: 53px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    opacity: 1;
  }
  
  .options {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
 
    background-color: #fff;
    display: none;
    z-index:10
  }

  
  .options li {
    padding: 8px;
    cursor: pointer;
  }
  
  .dropdown:hover .options {
    display: block;
  }