.item {
    height: 100%;
}

.recibo {
    width: auto;
    height: auto;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: var(--azul, #004994);
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.containerLogo {
    width: 192.95px;
    height: 51.99px;
    flex-shrink: 0;
}

.imagen {
    width: 200px;
    height: auto;
}

.sideTitle {
    display: flex;
    width: 238px;
    height: 52px;
    justify-content: center;
    flex-direction: column;
    flex-shrink: 0;
}

.text {
    color: var(--azul, #004994);
    text-align: right;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.containerDatos {
    height: 100%;
    width: auto;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 20px;
}

.headerDatos {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    width: -webkit-fill-available;
    justify-content: space-between;
}

.titleDatos {
    display: flex;
    width: 372px;
    height: 59px;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
}

.containerFecha {
    flex-shrink: 0;
    border: 2px;
    border-radius: 20px;
    margin: 20px;
    width: 40%;
    height: 45%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.titleFecha {
    width: 35%;
    height: 35px;
    border-radius: 25px 0px 0px 25px;
    color: rgb(118, 118, 118);
    background-color: #f8f9fa;
    border-width: thin;
    text-align: center;
    font-size: large;
    line-height: 2;
    border-style: solid;
    border-right: none;
    font-weight: 500;
}

.contrato {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 1.3;
    font-weight: 200;
}

.bodyContrato {
    margin: 20px;
    width: auto;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
}

.containerFooter {
    width: auto;
    height: min-content;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.textSup {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.textMid {
    text-align: center;
}

.textInf {
    text-align: center;
}

.containerTextura {
    background-image: url("./texturaHeader.png");
    background-size: cover;
    background-position: center;
    height: 103.3px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
};

.footer {
    display: flex;
    justify-content: center;
    height: 13%;
    background-image: url("./texturaFooter.png");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalLoading {
    display: flex;
    align-self: center;
    border-radius: 20px;
    border: 1px solid black;
    width: 250px;
    height: 250px;
    justify-content: center;
    z-index: 99;
    align-content: center;
    flex-direction: column;
    align-items: center;
}