.BodyContrato {
    padding: 20px;
}

.nroContrato {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
}

.titleContrato {
    text-align: center;
}

.ContainerTextContrato {
    line-height: 2;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    margin-top: 20px;
}

.parrafoReserva {
    margin-top: 15px !important;
    color: #495057;
}