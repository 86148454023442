@keyframes modal-animation {
  from {
    transform: translateY(-100px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.ContainerAux {
  /* width: 1122px; */
  width: 1066px;
  height: auto;
  padding: 65px 28px 39px 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: var(--Escala-de-grises-1, #f2f2f2);
  gap: 34px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  transition: all 0.3s linear;

  @media screen and (max-height: 780px) and (min-width: 500px) {
    /* Cuando la altura es menor o igual a 780px y el ancho es mayor o igual a 500px */
    padding: 30px 28px 30px 28px;
    transform: scale(0.78);
    margin-top: 9vh;
  }
}

@media (min-width: 0px) and (max-width: 1017px) {
  .ContainerAux {
    width: -webkit-fill-available;
    padding: 30px 28px 30px 28px;
    gap: 16px;
  }
}

.SolicitalaText {
  color: #fff;
  width: auto;
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 30px */
}

.contentIconPubli {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: flex-start;
  align-content: flex-end;
  justify-content: flex-start;
}

.ContentPublicidad {
  display: flex;
  flex-direction: row;
  gap: 42px;
  width: auto;
  align-items: center;
  justify-content: flex-end;
}

.ContentPublicidadLineWhite {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: auto;
  align-items: center;
  justify-content: flex-start;
  border-bottom: solid 2px #ffffff;
}

.ContainerModalCalculador {
  /* backdrop-filter: blur(3px); */
  /* width: -webkit-fill-available; */
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  animation: modal-animation 0.3s ease-in-out;
  justify-content: space-around;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 550px) and (max-width: 1017px) {
    position: initial;
    height: auto;
    backdrop-filter: none;
    top: 0px;
    /* border:solid 1px red; */
    /* margin-top:50px; */
  }

  @media (min-width: 0px) and (max-width: 500px) {
    position: initial;
    height: auto;
    backdrop-filter: none;
    /* border:solid 1px red; */
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px !important;
    top: 0px;
  }
}

.ContainerCruzModal {
  width: 98%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.ContainerModalCalculadorResultado {
  background-color: #f2f2f2;
  border-radius: 20px;
  width: 1122px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  z-index: 99;
  padding: 28px 0px 28px 0px;
  left: 40vh;
  bottom: 10vh;
}

.TitleCalculador {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: -webkit-fill-available;

  @media (min-width: 0px) and (max-width: 1017px) {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
}

.Title {
  width: 439px;
  color: var(--Azul-principal, #004993);
  font-family: Montserrat;
  font-size: 39px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;

  /* 46.8px */
  @media (min-width: 500px) and (max-width: 600px) {
    width: auto;
    font-size: 35px;
  }

  @media (min-width: 0px) and (max-width: 500px) {
    width: auto;
    font-size: 25px;
  }
}

.subTextCalculador {
  color: #1a1a1a;
  width: 548px;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
  /* 26.1px */
  letter-spacing: -0.09px;
  opacity: 0.6;

  @media (min-width: 0px) and (max-width: 600px) {
    width: auto;
    font-size: 22px;
  }
}

.ContainerInputs {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  justify-content: space-between;
  align-self: center;
}

.ContenedorInputsForm {
  width: -webkit-fill-available;
  height: auto;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 0px) and (max-width: 1017px) {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
}

.ContainerValores {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  background-color: #f9fafb;
  border-radius: 10px;

  width: auto;
  max-height: 53px !important;
  height: 53px;

  @media (min-width: 0px) and (max-width: 1017px) {
    width: -webkit-fill-available;
    /* border:solid 1px red; */
    flex-direction: column;
    max-height: none !important;
    height: auto;
    background-color: transparent !important;
    padding: 0px 0px 0px 0px;
  }
}

.input1 {
  padding-left: 18px;
  width: 222px;
  border: none;
  background-color: #f9fafb;
  outline: none;
  color: #1a1a1a;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
  border-radius: 10px;
  max-height: 53px !important;
  height: 53px;

  @media (min-width: 0px) and (max-width: 1017px) {
    width: -webkit-fill-available;
    border-radius: 8px;
    padding: 16px 36px 16px 36px;
    max-height: 23px !important;
    height: 23px;
  }
}

.input1::placeholder {
  color: #1a1a1a;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
  color: #1a1a1a;
  /* Puedes ajustar el color según tus preferencias */
}

.input2::placeholder {
  color: #1a1a1a;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
  color: #1a1a1a;
  /* Puedes ajustar el color según tus preferencias */
}

.lineaAzul {
  background: #004993;
  height: 37px;
  width: 2px;
  max-height: 37px !important;

  @media (min-width: 0px) and (max-width: 1017px) {
    display: none;
  }
}

.bolitaBlanca {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #ffffff;
  position: relative;
  top: 24px;
  right: 50%;
}

.input2 {
  padding-left: 18px;
  width: 222px;
  border: none;
  background-color: #f9fafb;
  outline: none;
  color: #1a1a1a;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  box-sizing: border-box;
  border-radius: 10px;
  max-height: 53px !important;
  height: 53px;

  @media (min-width: 0px) and (max-width: 1017px) {
    width: 100%;
    border-radius: 8px;
    padding: 16px 36px 16px 36px;
  }
}

.input2 option {
  font-family: Montserrat;
  /* Puedes ajustar esto según tus necesidades */
  font-size: 16px;
  /* Puedes ajustar esto según tus necesidades */
  width: 200px !important;
  max-width: 200px !important;
}

.flechaCalcula {
  background-color: #004993;
  border: none;
  border-radius: 100%;

  width: 56px;
  height: 56px;
  max-height: 56px !important;
  max-width: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px !important;

  @media (min-width: 0px) and (max-width: 1017px) {
    max-width: 100px !important;
    border-radius: 30px !important;
    width: 100px;
  }
}
/* 
.ContainerPasos {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  background-image: url(/_next/static/media/bg_Modal.84e91f94.png);
  color: #f2f2f2;
  border-radius: 20px;
  background-repeat: no-repeat;
  padding: 32px 22px;
  width: -webkit-fill-available;
  background-size: cover;
  background-position: center;

  @media (max-width: 970px) {
    display: none;
  }
} */

.Paso {
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
  height: 44px;
  max-height: 44px !important;
  border: 2px solid #f9fafb;
  margin: 15px;
  border-radius: 15px;
  justify-content: center;
  gap: 10px;
}

.ContainerCards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: -webkit-fill-available; */
  width: 80%;
  align-self: center;

  @media (min-width: 0px) and (max-width: 1017px) {
    flex-direction: column;
    gap: 16px;
    width: -webkit-fill-available;
    align-items: center;
  }
}

.ExtraContainerCards {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  gap: 35px;
}

.contFlechaSolicitar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border: 2px solid #004993;
  border-radius: 100%;

  @media (min-width: 0px) and (max-width: 500px) {
    display: none;
  }
}

.flechaSolicitar {
  width: 24px;
  height: 24px;
}

.textDisclaimer {
  font-size: 10px;
  font-family: Montserrat;
  font-size: italic;
  line-height: 12px;
  font-weight: 700;
  color: #1a1a1a;
  opacity: 0.6;
  text-align: center;
}

.containerButtonCard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  width: -webkit-fill-available;
  cursor: pointer;
}

.buttonSolicitar {
  color: #f2f2f2;
  font-size: 22px;
  font-family: Montserrat;
  font-weight: 700;
  background-color: #004993;
  padding: 13px 36px 13px 36px;
  border-radius: 30px;
  border: none;
}

.buttonSolicitarDisabled {
  color: #f2f2f2;
  font-size: 22px;
  font-family: Montserrat;
  font-weight: 700;
  background-color: #004993;
  padding: 0px 20px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  border: solid 2px #004993;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;

  @media (min-width: 0px) and (max-width: 365px) {
    font-size: 16px;
  }
}

.buttonSolicitarDisabled:hover {
  color: #004993;
  background-color: #ffffff;
  border: solid 2px #004993;
}

.buttonSolicitar:hover {
  background-color: #0076b9;
}

.textoCalculador {
  color: #f2f2f2;
  font-size: 18px;
  font-family: Montserrat;
  font-weight: 700;
  width: auto;
}

@media (max-width: 500px) {
  .ContainerModalCalculadorResultado {
    /* left: auto; */
    /* bottom: auto; */
    position: initial;
    width: -webkit-fill-available;
    height: auto;
    margin: 40px 15px 15px 15px;
  }

  .ContainerCruzModal {
    display: none;
  }

  .TitleCalculador {
    flex-direction: column;
    margin: 18px;
    gap: 20px;
  }

  .ContainerInputs {
    flex-direction: column;
    gap: 20px;
  }

  .input1 {
    border-right: none;
    align-self: flex-start;
    width: -webkit-fill-available;
  }

  .input2 {
    align-self: flex-start;
    width: -webkit-fill-available;
  }

  .flechaCalcula {
    margin-top: 35px;
    padding: 0 20px;
  }

  .ContainerPasos {
    display: none;
  }

  .ExtraContainerCards {
    gap: 40px;
  }
}
